import { ProjectOrWorkspace } from '@/definitions-frontend/types-client-only';

/**
 * Returns true if date1 is newer than date2.
 */
export function isDateNewer(date1: Date, date2: Date) {
	return date1.getTime() > date2.getTime();
}

/**
 * Sorts the provided entity by LastVisitDate.
 * @author PB
 * @param entities Entity to sort.
 */
export function sortByLastVisit<EntityT extends ProjectOrWorkspace>(entities: EntityT[]): EntityT[] {
	return entities.sort((a: EntityT, b: EntityT) => {
		if (!a.LastVisitDate) {
			return 1;
		} else if (!b.LastVisitDate) {
			return -1;
		}
		const date1 = new Date(a.LastVisitDate);
		const date2 = new Date(b.LastVisitDate);
		if (date1 < date2) {
			return 1;
		} else if (date2 < date1) {
			return -1;
		} else {
			return 0;
		}
	});
}

/**
 * For a given date, get the ISO week number.
 * Taken from https://stackoverflow.com/a/6117889
 *
 * Algorithm is to find nearest Thursday, its year is the year of the week number. Then get weeks between that date
 * and the first day of that year.
 * Note that dates in one year can be weeks of previous or next year, overlap is up to 3 days.
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 */
export function getWeek(d: Date): string {
	// Copy date so don't modify original
	d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
	// Set to nearest Thursday: current date + 4 - current day number
	// Make Sunday's day number 7
	d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
	// Get first day of year
	var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
	// Calculate full weeks to nearest Thursday
	// @ts-ignore
	var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
	// Return year and week number
	return `${d.getUTCFullYear()}-${weekNo}`;
}

/**
 * Returns the duration in a pretty print format with minutes as the smallest unit, like
 * "2 days 5 hours 1 minute" or "1 hour 0 minutes".
 * Seconds get clamped, not rounded, so providing 59 (seconds) results in "0 minutes".
 * @author OK
 * @param durationSec Duration in seconds.
 */
export function getDurationPretty(durationSec: number): string {
	const durationMs = durationSec * 1000;
	const days = Math.floor(durationMs / 86400000);
	const dayStr = (days > 0) ? (days > 1 ? `${days} days ` : `${days} day `) : '';
	const timeIsoStr = new Date(durationMs).toISOString();
	const hourIsoStr = timeIsoStr.substring(11, 13);
	const minIsoStr = timeIsoStr.substring(14, 16);

	let hourStr = '';
	if (hourIsoStr === '00') {
		hourStr = '';
	} else if (hourIsoStr.startsWith('01')) {
		hourStr = '1 hour ';
	} else if (hourIsoStr.startsWith('0')) {
		hourStr = hourIsoStr.substring(1) + ' hours ';
	} else {
		hourStr = hourIsoStr + ' hours ';
	}

	let minStr = '';
	if (minIsoStr.startsWith('01')) {
		minStr = '1 minute';
	} else if (minIsoStr.startsWith('0')) {
		minStr = minIsoStr.substring(1) + ' minutes';
	} else {
		minStr = minIsoStr + ' minutes';
	}

	return `${dayStr}${hourStr}${minStr}`;
}
