import { ISubscription } from '@/definitions/interfaces';
import { AuthzBaseInterface, AuthzInterface, AuthzType } from '@faroconnect/authz-client';
import { BaseEntity } from '@/classes/BaseEntity';
import { $assert } from '@faroconnect/utils';
import { config } from '@/config';
import { demoWorkspace } from '@/definitions-frontend/demo-workspace';
import { webShareUrl } from '@/utils/route';

const defaultWorkspaceImage = require('@/assets/workspace.svg');

export type DomainCreationStatus = 'pending' | 'created' | 'error';
export type WebshareRegionUpper = 'EU' | 'US';

export interface IWorkspace extends AuthzInterface.IWorkspace {
	Region: AuthzType.WebshareRegion | '';
	License?: string;
	Subscription?: ISubscription;
	WorkspaceUserState?: AuthzType.UserState;
	LastVisitDate: string;
	/**
	 * Flag set to true if it's a Faro demo workspace
	 */
	IsDemo: boolean;
	ErpId?: string;
	Notes?: string;
	CommerciallyRelevant?: boolean;
	BilledSubscription?: boolean;
	BilledModule?: boolean;
}

export class Workspace extends BaseEntity implements IWorkspace {
	public static readonly constructorName = Workspace.name;

	public static fromResponse(json: IWorkspace) {
		return new Workspace(json);
	}

	public static forRequest(json: Partial<IWorkspace>) {
		return new Workspace(json);
	}

	public readonly isWorkspace: boolean = true;

	public Class: 'Workspace' = 'Workspace';
	public ImgUrl: string;
	public DefaultImgUrl: string = defaultWorkspaceImage;
	public License: string;
	public State: AuthzType.WorkspaceState | null;
	public Region: AuthzType.WebshareRegion | '';
	public RegionUpper: WebshareRegionUpper | ''; // For display purposes.
	public Subscription?: ISubscription;
	public WebshareDomainName: string;
	public WebshareAlias: string;
	public Owner: string;
	public WorkspaceUserState?: AuthzType.UserState;
	/** @deprecated Use Region instead. */
	public WebshareRegion: AuthzType.WebshareRegion;
	public Inviter?: AuthzBaseInterface.InviterI;
	public LastVisitDate: string = '';
	public ShareDate: string = '';
	public DomainCreationStatus: DomainCreationStatus;
	public InitialOwner: string;
	public IsDemo: boolean;
	public ErpId?: string;
	public ReadOnly: boolean;
	public XgRedirect: boolean;
	public Notes?: string;
	public CommerciallyRelevant?: boolean;
	public BilledSubscription?: boolean;
	public BilledModule?: boolean;

	protected constructor(json: Partial<IWorkspace>) {
		super(json);
		this.License = json.License ?? '';
		this.State = json.State ?? null; // Cannot use '' since we must be compatible with AuthzInterface.IWorkspace.
		this.WorkspaceUserState = json.WorkspaceUserState;
		// json.Region:         from Subscription Service
		// json.WebshareRegion: from AuthZ
		this.Region = json.Region ?? json.WebshareRegion ?? '';
		this.RegionUpper = this.Region.toUpperCase() as (WebshareRegionUpper | '');
		$assert.Assert(this.Region === 'eu' || this.Region === 'us');
		this.WebshareDomainName = json.WebshareDomainName ?? ''; // from AuthZ
		this.WebshareAlias = json.WebshareAlias ?? ''; // from AuthZ
		this.WebshareRegion = this.Region as AuthzType.WebshareRegion;
		this.Owner = json.Owner ?? '';
		this.Subscription = json.Subscription;
		this.Inviter = json.Inviter;
		this.ShareDate = json.ShareDate ?? '';
		this.LastVisitDate = json.LastVisitDate ?? '';
		this.DomainCreationStatus = 'created'; // Initiate the property as created so that workspace tile will load smoothly if it already exists.
		this.InitialOwner = json.InitialOwner ?? '';
		this.IsDemo = json.IsDemo ?? false;
		this.ErpId = json.ErpId ?? '';
		this.ReadOnly = json.ReadOnly ?? false;
		this.XgRedirect = json.XgRedirect ?? false;
		this.Notes = json.Notes ?? '';
		this.CommerciallyRelevant = json.CommerciallyRelevant ?? false;
		this.BilledSubscription = json.BilledSubscription ?? false;
		this.BilledModule = json.BilledModule ?? false;

		// We use a timestamp as part of the URL to prevent any caching issues.
		// To benefit from caching, we would have to use "CustomerLogoRevision" from
		// `https://${this.Name}.webshare-america.net/domain/settings`.
		const timestamp = Date.now();
		// All pending workspaces seem to have Region === 'eu', which should be okay.
		// For DEV, it's a known issue that some workspaces have Region === 'eu' but are either in the US or don't exist.
		$assert.Assert((this.Region === 'eu' ? config.webShareEU : config.webShareUS) || this.State === 'pending' || (this.Region === 'eu' && config.env === 'dev'), `Workspace ${this.UUID} ${this.Name} is in region ${this.Region}, but config.webShareEU/US is not set!`);
		// By using the "workspaceuuid" query instead of an actual subdomain, we avoid showing the "faro" domain logo
		// for non-existent WebShare domains, or if the customer hasn't set up a logo.
		// Also, for Workspaces constructed from the SubSvc response, WebshareDomainName is not set, so better use the
		// UUID instead to avoid failed requests.
		if (this.IsDemo === true) {
			// If it's a demo workspace use the hardcoded WebShareHostName to set the ImgUrl.
			this.ImgUrl = `https://faro.${demoWorkspace.webshareHostname}/data/domain/customer-logo/any?revision=${timestamp}&workspaceuuid=${this.UUID}`;
		} else {
			this.ImgUrl = webShareUrl(this.Region, 'faro', `/data/domain/customer-logo/any?revision=${timestamp}&workspaceuuid=${this.UUID}`);
		}
	}

	/**
	 * @returns The full WebShare URL, like 'https://customer.websharecloud.com/'.
	 */
	public get webShareUrl(): string | null {
		// If it's a demo workspace return the hardcoded webShareUrl.
		if (this.IsDemo) {
			return demoWorkspace.webshareUrl;
		}

		// If workspace was only fetched from SubSvc, WebshareAlias and WebshareDomainName are not set.
		// But SubSvc stores the original workspace name, which equals the WebshareDomainName, so it'll work.
		const subdomain = this.WebshareAlias || this.WebshareDomainName || this.Name;
		if (!subdomain || !this.Region) {
			return null;
		}

		return webShareUrl(this.Region, subdomain, '/');
	}

	public get isActive(): boolean {
		// If the workspace exists only in AuthZ, the state will be null.
		return !this.State || this.State === 'active';
	}
	public get isPending(): boolean {
		return this.State === 'pending';
	}
	public get isDeactivated(): boolean {
		return this.State === 'deactivated';
	}

	/**
	 * Returns whether the user was invited to this workspace and hasn't accepted the invitation.
	 */
	public get isPendingUserInvitation(): boolean {
		return this.WorkspaceUserState === 'pending';
	}

	/**
	 * Returns a shortened name of the workspace's subscription for the table in the Migration Dashboard.
	 * @author OK
	 */
	public get SubscriptionShortName(): string {
		const sub = this.Subscription?.type ?? '';

		if (sub.endsWith('_BASE')) {
			return 'WS Base';
		} else if (sub.endsWith('_PRO')) {
			return 'WS Pro';
		} else if (sub === 'faro_sphere_enterprisecloud') {
			return 'Enterprise';
		} else if (sub === 'faro_sphere_professional') {
			return 'Pro';
		} else if (sub === 'faro_sphere_base') {
			return 'Base';
		} else {
			return 'n/a';
		}
	}

	/**
	 * Returns true if the workspace is created more than one day ago.
	 */
	public isOlderThanOneDay(): boolean {
		const oneDay = 24 * 60 * 60 * 1000;
		if (Date.now() - Date.parse(this.CreationDate) > oneDay) {
			return true;
		}
		return false;
	}

	/**
	 * Merge two Workspace objects.
	 * Both "this" and "workspace" can be from AuthZ or Subscription Service.
	 * @param workspace Workspace to take each attribute from that is missing on "this".
	 */
	public merge(workspace: Workspace) {
		// No merge for Class
		this.CreationDate = this.CreationDate || workspace.CreationDate;
		this.DefaultImgUrl = this.DefaultImgUrl || workspace.ImgUrl;
		this.Description = this.Description || workspace.Description;
		this.ImgUrl = this.ImgUrl || workspace.ImgUrl;
		this.License = this.License || workspace.License;
		this.Name = this.Name || workspace.Name;
		this.Owner = this.Owner || workspace.Owner;
		this.Region = this.Region || workspace.Region;
		this.RegionUpper = (this.Region || '').toUpperCase() as (WebshareRegionUpper | '');
		this.State = this.State || workspace.State;
		this.Subscription = this.Subscription || workspace.Subscription;
		// No merge for UUID
		this.UpdateDate = this.UpdateDate || workspace.UpdateDate;
		this.Version = this.Version || workspace.Version;
		this.WebshareDomainName = this.WebshareDomainName || workspace.WebshareDomainName;
		this.WebshareAlias = this.WebshareAlias || workspace.WebshareAlias;
		this.ErpId = this.ErpId || workspace.ErpId;
		this.ReadOnly = this.ReadOnly || workspace.ReadOnly;
		this.XgRedirect = this.XgRedirect || workspace.XgRedirect;
		this.Notes = this.Notes || workspace.Notes;
		this.CommerciallyRelevant = this.CommerciallyRelevant || workspace.CommerciallyRelevant;
		this.BilledSubscription = this.BilledSubscription || workspace.BilledSubscription;
		this.BilledModule = this.BilledModule || workspace.BilledModule;

		this.Inviter = this.Inviter || workspace.Inviter;

		const isAuthZWorkspace = !!workspace.WebshareDomainName;
		if (isAuthZWorkspace) {
			// Make sure that we prefer the value from AuthZ for redundantly stored attributes (active workspaces).
			// Otherwise, e.g. editing the description does not work in the UI, since the outdated value from SubSvc is used.
			// Proper fix: FC-3249
			this.Name = workspace.Name;
			this.Description = workspace.Description;
		}
		if (workspace.WorkspaceUserState) {
			this.WorkspaceUserState = workspace.WorkspaceUserState;
		}
	}

	/**
	 * Update workspace properties with new data.
	 * @param workspace Workspace that its properties replaced the current workspace.
	 * It should not be another workspace, it should be the same workspaces with some more/less properties.
	 * If UUID is set in "workspace", is should be equal to the current class ("this").
	 * @returns Current class by new properties.
	 */
	public updateProperties(workspace: Partial<IWorkspace>) {
		if (workspace.UUID && workspace.UUID !== this.UUID) {
			throw Error('You can\'t change uuid of the workspace');
		}
		return Object.assign(this, workspace);
	}
}
